<template>
  <b-modal
      id="image-upload"
      ref="image-upload"
      centered
      footer-class="flex-nowrap"
      no-close-on-backdrop
      @ok="confirmUpload"
  >
    <template #modal-header>
      <h3>{{ modalTitle }}</h3>
    </template>
    <div>
      <div class="mx-3 mt-3" v-if="usePersonalization === true">
        <p>{{ $t('modals.imageUpload.imageTitle') }}</p>
      </div>
      <image-upload
          :image-object="imageObjectOfModalWindow"
          :allowFileSizeValidation="allowFileSizeValidation"
          :acceptedFileTypes="typeOfUpload"
          :type="type"
          @upload-image-object="updateUploadImageObject"
          @disable-modal-ok-button="disableOkButtonManually"
          @file-removed="removeImage"
      />
      <div class="m-3 align-right" v-if="hasPersonalization">
        <button
            class="btn green-button-transparent border-0 btn-secondary"
            v-if="usePersonalization === false"
            @click="usePersonalization = true"
        >
          {{ $t('modals.imageUpload.usePersonalization') }}
        </button>
        <button
            class="btn green-button-transparent border-0 btn-secondary"
            v-if="usePersonalization === true"
            @click="disablePersonalization"
        >
          {{ $t('modals.imageUpload.disablePersonalization') }}
        </button>
      </div>
      <div v-if="usePersonalization">
        <div class="m-3" v-if="personalizationSetIdArray">
          <b-form-select
              v-model="setId"
              :options="personalizationSetIdArray"
              @change="valueId = null"
          ></b-form-select>
        </div>
        <div class="m-3" v-if="setId">
          <b-form-select
              v-model="valueId"
              :options="personalizationValueArray"
              @change="updatePersonalization"
          ></b-form-select>
        </div>
      </div>
    </div>
    <template #modal-footer="{ close, ok }">
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="close()"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          variant="primary"
          class="p-3"
          block
          @click="confirmUpload"
          :disabled="computedDisableButton"
      >
        {{ $t('buttons.ok') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'ModalUploadingImage',
  components: {
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
  },
  props: {
    personalization: {
      type: Array,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    imageObject: {
      type: Object,
      required: false,
      default() {
        return {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        };
      },
    },
  },
  data: () => ({
    imageReturnObj: null,
    disableOkButton: true,
    usePersonalization: false,
    setId: null,
    valueId: null,
  }),
  watch: {
    imageObject(newObject) {
      this.setupUploadImageModal(newObject);
    },
  },
  mounted() {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'image-upload') {
        this.disableOkButtonManually(true);
      }
    });
  },
  computed: {
    hasPersonalization() {
      if (this.personalization) {
        return this.personalization.length > 0;
      }
      return false;
    },
    personalizationSetIdArray() {
      const personalizationSetIdArray = [];
      if (this.personalization) {
        personalizationSetIdArray.push({
          value: null,
          text: this.$t('modals.imageUpload.selectOption'),
        });
        this.personalization.forEach((personalizationSet) => {
          // check first if the schema contains a mediaField
          if (personalizationSet.schema) {
            const mediaField = personalizationSet.schema.find(
              (schemaObj) => schemaObj.type === 'MediaField',
            );
            if (mediaField) {
              personalizationSetIdArray.push({
                value: personalizationSet.personalized_variable_set_id,
                text: personalizationSet.name,
              });
            }
          }
        });
        return personalizationSetIdArray;
      }
      return null;
    },
    personalizationValueArray() {
      const personalizationValueArray = [];
      if (this.setId) {
        const personalizationSet = this.personalization.find(
          // eslint-disable-next-line max-len
          (personalizationSetObj) => personalizationSetObj.personalized_variable_set_id === this.setId,
        );
        if (personalizationSet) {
          personalizationValueArray.push({
            value: null,
            text: this.$t('modals.imageUpload.selectOption'),
          });
          // find the media attribute
          const mediaFieldArray = personalizationSet.schema.filter(
            (schemaObj) => schemaObj.type === 'MediaField',
          ).map((mediaObj) => ({ value: mediaObj.id, text: mediaObj.name }));
          return [
            {
              value: null,
              text: this.$t('modals.imageUpload.selectOption'),
            },
            ...mediaFieldArray,
          ];
        }
      }
      return null;
    },
    modalTitle() {
      switch (this.type) {
        case 'audio':
          return this.$t('modals.imageUpload.audioTitle');
        case 'video' || 'video_webm':
          return this.$t('modals.imageUpload.videoTitle');
        case 'pdf':
          return this.$t('modals.imageUpload.pdfTitle');
        default:
          return this.$t('modals.imageUpload.imageTitle');
      }
    },
    imageObjectOfModalWindow() {
      return this.imageObject;
    },
    typeOfUpload() {
      if (this.type === 'audio') {
        return 'audio/mp3,audio/mpeg';
      }
      if (this.type === 'video') {
        return 'video/mp4';
      }
      if (this.type === 'video_webm') {
        return 'video/webm';
      }
      if (this.type === 'pdf') {
        return 'application/pdf';
      }
      return 'image/png,image/jpeg,image/gif,image/svg+xml';
    },
    allowFileSizeValidation() {
      return this.type === 'audio' || this.type === 'video' || this.type === 'video_webm' || this.type === 'pdf';
    },
    computedDisableButton() {
      if (this.valueId && this.setId && this.imageReturnObj?.url) {
        return false;
      }
      return this.disableOkButton;
    },
  },
  methods: {
    removeImage() {
      if (this.imageReturnObj) {
        this.imageReturnObj.url = null;
      }
      this.disableOkButton = true;
    },
    setupUploadImageModal(newObject) {
      this.setId = null;
      this.valueId = null;
      this.usePersonalization = false;
      if (newObject.personalization && this.personalization) {
        const personalizationSetIndex = this.personalization.findIndex(
          // eslint-disable-next-line max-len
          (persoSet) => persoSet.personalized_variable_set_id === newObject.personalization.setId,
        );
        if (personalizationSetIndex !== -1
          && this.personalization[personalizationSetIndex].schema
        ) {
          if (this.personalization[personalizationSetIndex].schema.find(
            (elem) => elem.id === newObject.personalization.valueId,
          )) {
            this.usePersonalization = true;
            this.setId = newObject.personalization.setId;
            this.valueId = newObject.personalization.valueId;
          }
        }
      }
      this.imageReturnObj = cloneDeep(this.imageObject);
    },
    disablePersonalization() {
      this.usePersonalization = false;
      this.valueId = null;
      this.setId = null;
      if (this.imageReturnObj) {
        delete this.imageReturnObj.personalization;
      }
      delete this.imageReturnObj.personalization;
      if (this.imageReturnObj?.url) {
        this.disableOkButton = false;
      }
    },
    updateUploadImageObject(imageObj) {
      this.imageReturnObj = imageObj;
      if (this.setId && this.valueId) {
        this.imageReturnObj.personalization = {
          setId: this.setId,
          valueId: this.valueId,
        };
      } else {
        delete this.imageReturnObj.personalization;
      }
      this.disableOkButton = imageObj === null;
    },
    disableOkButtonManually(disableButton) {
      if (disableButton) {
        this.disableOkButton = true;
      }
    },
    updatePersonalization(value) {
      if (value) {
        this.imageReturnObj.personalization = {
          setId: this.setId,
          valueId: value,
        };
      } else {
        delete this.imageReturnObj.personalization;
      }
    },
    confirmUpload() {
      this.$emit('image-uploaded', this.imageReturnObj);
      this.$refs['image-upload'].hide();
      this.imageReturnObj = null;
    },
    cancelModal() {
      this.$refs['image-upload'].hide();
      this.imageReturnObj = null;
      this.$emit('cancel-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.delete-icon svg {
  font-size: 80px;
}

/deep/ button.disabled {
  cursor: not-allowed;
}
</style>
