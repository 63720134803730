var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "image-upload",
      attrs: {
        id: "image-upload",
        centered: "",
        "footer-class": "flex-nowrap",
        "no-close-on-backdrop": ""
      },
      on: { ok: _vm.confirmUpload },
      scopedSlots: _vm._u([
        {
          key: "modal-header",
          fn: function() {
            return [_c("h3", [_vm._v(_vm._s(_vm.modalTitle))])]
          },
          proxy: true
        },
        {
          key: "modal-footer",
          fn: function({ close, ok }) {
            return [
              _c(
                "b-button",
                {
                  staticClass: "p-3 border-right-light-grey",
                  attrs: { block: "" },
                  on: {
                    click: function($event) {
                      return close()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "p-3",
                  attrs: {
                    variant: "primary",
                    block: "",
                    disabled: _vm.computedDisableButton
                  },
                  on: { click: _vm.confirmUpload }
                },
                [_vm._v(" " + _vm._s(_vm.$t("buttons.ok")) + " ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "div",
        [
          _vm.usePersonalization === true
            ? _c("div", { staticClass: "mx-3 mt-3" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("modals.imageUpload.imageTitle")))
                ])
              ])
            : _vm._e(),
          _c("image-upload", {
            attrs: {
              "image-object": _vm.imageObjectOfModalWindow,
              allowFileSizeValidation: _vm.allowFileSizeValidation,
              acceptedFileTypes: _vm.typeOfUpload,
              type: _vm.type
            },
            on: {
              "upload-image-object": _vm.updateUploadImageObject,
              "disable-modal-ok-button": _vm.disableOkButtonManually,
              "file-removed": _vm.removeImage
            }
          }),
          _vm.hasPersonalization
            ? _c("div", { staticClass: "m-3 align-right" }, [
                _vm.usePersonalization === false
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn green-button-transparent border-0 btn-secondary",
                        on: {
                          click: function($event) {
                            _vm.usePersonalization = true
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("modals.imageUpload.usePersonalization")
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.usePersonalization === true
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "btn green-button-transparent border-0 btn-secondary",
                        on: { click: _vm.disablePersonalization }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "modals.imageUpload.disablePersonalization"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm.usePersonalization
            ? _c("div", [
                _vm.personalizationSetIdArray
                  ? _c(
                      "div",
                      { staticClass: "m-3" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.personalizationSetIdArray },
                          on: {
                            change: function($event) {
                              _vm.valueId = null
                            }
                          },
                          model: {
                            value: _vm.setId,
                            callback: function($$v) {
                              _vm.setId = $$v
                            },
                            expression: "setId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.setId
                  ? _c(
                      "div",
                      { staticClass: "m-3" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.personalizationValueArray },
                          on: { change: _vm.updatePersonalization },
                          model: {
                            value: _vm.valueId,
                            callback: function($$v) {
                              _vm.valueId = $$v
                            },
                            expression: "valueId"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }